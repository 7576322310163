import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Lpimage from "../../images/clientLogos/lp_logo.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/krian_prakashan.png"

import Igp from "../../images/clientLogos/igp.png"
import Bajajfinserv from "../../images/clientLogos/Bajaj-Finserv.webp"
import Proptiger from "../../images/clientLogos/proptiger_logo.png"
import Housing from "../../images/clientLogos/housing.png"
import MvnGroup from "../../images/clientLogos/MVN.png"
import Ceramics from "../../images/clientLogos/ceramiclogo.png"
import Dawaduniya from "../../images/clientLogos/DawaDuniyaLogo.png"
import IncredibleGifts from "../../images/clientLogos/IG-logo.png"
import OlivaClinic from "../../images/clientLogos/oliva-logo.png"
import Vmf from "../../images/clientLogos/vmf-logo.png"
import UkHomes from "../../images/clientLogos/uk-homes-logo.png"
import UpGradeMySite from "../../images/clientLogos/upgrade-my-site.png"
import Apollo from "../../images/clientLogos/apollo.png"
import PFEC from "../../images/clientLogos/pfec-bd-logo.png"
import MedX from "../../images/clientLogos/medxpharmacy.png"
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png wpcf7mailsent"



const Container = styled.ul`
	${tw`relative z-0`}
	li{
		${tw`max-w-[150px] mr-16`}
	}

`;


export default ({
	clientLogos = [

		{
			id: 1,
			logoSrc: Housing,
			clientName: "housing.com",
			increaseSize: true
		},

		{
			id: 2,
			logoSrc: Proptiger,
			clientName: "proptiger",
			increaseSize: true
		},

		{
			id: 3,
			logoSrc: Bajajfinserv,
			clientName: "Bajaj Finserv",
			increaseSize: true,
		},

		{
			id: 4,
			logoSrc: MvnGroup,
			clientName: "MVN Group",
			increaseSize: true,
		},
		{
			id: 5,
			logoSrc: Ceramics,
			clientName: "Ceramics Arts",
			increaseSize: true,
		},
		{
			id: 6,
			logoSrc: Dawaduniya,
			clientName: "DawaDuniya",
			increaseSize: false
		},
		{
			id: 7,
			logoSrc: MedX,
			clientName: "Medx Pharmacy",
			increaseSize: false
		},
		{
			id: 8,
			logoSrc: Kiranprakashan,
			clientName: "Kiranprakashan",
			increaseSize: false
		},
		{
			id: 9,
			logoSrc: IncredibleGifts,
			clientName: "Incrediblegifts",
			increaseSize: false
		},
		{
			id: 10,
			logoSrc: OlivaClinic,
			clientName: "OlivaClinic",
			increaseSize: false
		},
		{
			id: 11,
			logoSrc: Vmf,
			clientName: "Vmf",
			increaseSize: false
		},

		{
			id: 12,
			logoSrc: UkHomes,
			clientName: "UkHomes",
			increaseSize: false
		},
		{
			id: 13,
			logoSrc: UpGradeMySite,
			clientName: "UpGradeMySite",
			increaseSize: false
		},
		// {
		// 	id: 14,
		// 	logoSrc: Apollo,
		// 	clientName: "Apollo",
		// 	increaseSize: false
		// },
		{
			id: 15,
			logoSrc: PFEC,
			clientName: "PFEC",
			increaseSize: false
		}
	]
}) => {


	return (
		<Container className="wr-clients">
			<div className='clientSlider'>
				<Marquee gradient={false}>
					{clientLogos.map((item, index) => (
						<li key={item.id} >
							<img src={item.logoSrc} alt={item.clientName} />
						</li>
					))}
				</Marquee>
			</div>
		</Container>
	);
};
