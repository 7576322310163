import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";
import OurClients from "../clients/Clients.js";

const Container = tw.div`relative lg:px-0 xs:px-2 py-5`;
const TwoColumn = tw.div`flex flex-col-reverse lg:flex-row-reverse lg:items-center max-w-screen-xl mx-auto md:pt-20 lg:pt-0 border-b-1`;
const LeftColumn = tw.div`relative flex-1 text-center lg:text-left max-w-lg mx-auto lg:max-w-none`;


const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-black leading-tight mt-24 text-center md:mt-0 `;
const Paragraph = tw.p`p-0 text-xl text-center xl:text-2xl p-5`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  button {
    ${tw`w-8/12 bg-brand-100 text-gray-100 xs:m-auto lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-brand-200 transition duration-300`}
  }
`;

const ClientSection = tw.section`text-center bg-indigo-800 max-w-screen-xl mx-auto rounded-lg`;

const Grid = tw.div`grid mt-0 md:grid-cols-2 gap-8 mt-4`;
const Card = styled.div`
  ${tw`flex items-center p-2 rounded-lg shadow-md`}
  background: #f9f9f9;
`;

const Icon = tw.div`w-8 h-8 p-4 flex items-center justify-center rounded-full `;
const Text = tw.p`ml-4 text-gray-700 text-xl text-black`;
const Highlight = tw.span`text-black font-semibold`;

export default ({
  roundedHeaderButton,
  mainTitle = '',
  subTitle = '',
  imageSrc = '',
  buttonText = '',
  buttonUrl = 'https://wa.me/919971200884/?text=Hi, I am interested to take eisolutions services.',
  showClient = true
}) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container> 
        <TwoColumn>
          <LeftColumn>
            {mainTitle && <Heading>{mainTitle}</Heading>}
            {subTitle && <Paragraph>{subTitle}</Paragraph>}
            <Grid>
              <div>
                <h3 tw="text-3xl text-center font-bold mb-4"><strong>What We Don't Do</strong></h3>
                <Card>
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We never resort to using <Highlight>ready-made templates</Highlight> for building subpar websites.</Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t neglect your brand identity by using <Highlight>outdated or unoriginal </Highlight>approaches.</Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t treat mobile and enterprise app development as an <Highlight>afterthought</Highlight>.</Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t compromise on <Highlight>design quality</Highlight> with <Highlight>generic UI/UX solutions</Highlight>.</Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t <Highlight>fall behind</Highlight> in technology trends like <Highlight>Generative AI</Highlight>.</Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t offer a <Highlight>one-size-fits-all</Highlight> solution that lacks flexibility.</Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t compromise on <Highlight>quality</Highlight> just to provide a low-cost solution.</Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t just supply <Highlight>resources </Highlight> without ensuring the right expertise and fit.</Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-brand-100">
                    <span tw="text-white text-2xl">✖</span>
                  </Icon>
                  <Text>We don’t provide <Highlight>generic e-commerce solutions</Highlight> that fail to meet unique business needs.</Text>
                </Card>
              </div>
              <div>
                <h3 tw="text-3xl text-center font-bold mb-4"><strong>EiSolutions</strong></h3>
                <Card>
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We ensure that to deliver<Highlight>high-quality website</Highlight> exclusively designed for you and your business needs.
                  </Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We craft unique and powerful <Highlight>brand identities</Highlight> to make your business stand out.
                  </Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We specialize in <Highlight>mobile and enterprise app</Highlight> development, through <Highlight>custom solutions</Highlight>.
                  </Text>
                </Card>
                <Card tw="mt-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We provide exceptional <Highlight>UI/UX design</Highlight> for a <Highlight>seamless</Highlight> user experience.
                  </Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We leverage <Highlight>cutting-edge Generative AI</Highlight> to enhance <Highlight>innovation and automation</Highlight>.
                  </Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We provide a <Highlight>One-Stop Solution</Highlight>, covering all aspects under one roof.
                  </Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We deliver <Highlight>cost-effective solutions</Highlight> without sacrificing quality and innovation.
                  </Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We act as a <Highlight>client resource provider</Highlight>, offering skilled professionals for the right projects.
                  </Text>
                </Card>
                <Card tw="mt-4 mb-4">
                  <Icon tw="bg-green-700">
                    <span tw="text-white text-2xl">✔</span>
                  </Icon>
                  <Text>
                  We specialize in <Highlight>customized e-commerce solutions</Highlight>, tailored to your business needs.
                  </Text>
                </Card>
              </div>
            </Grid>
            {/* {buttonText && (
              <Actions>
                <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
                  <button className="bt-round">{buttonText}</button>
                </a>
              </Actions>
            )} */}
          </LeftColumn>
        </TwoColumn>
        {showClient && (
          <ClientSection>
            <OurClients />
          </ClientSection>
        )}
      </Container>
    </>
  );
};
