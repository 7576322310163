//pages/Othersvsus.js
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Hero from "components/hero/OtherVsUsContent.js";
import Design from "images/others_vs_us_image.png";

export default () => {
  return (
    <AnimationRevealPage>
      
     <Hero
     roundedHeaderButton={true}
     mainTitle="Why We Are Better Than Others"
     subTitle="When we say it, we mean it. See for yourself."
     imageSrc={Design}
     showClient={false} 
     buttonText="Check It"
     />
      <Footer />
    </AnimationRevealPage>
  );
};
